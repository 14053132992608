import styled from "styled-components";

export const StyledVideoWrappe = styled.div`
    display: flex;
    justify-content: center;
    height: 450px;
    padding: 0 80px;
    margin-bottom: 145px;
`;

export const StyledCodegena = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.27198%;
`;

export const StyledIFrame = styled.iframe`
    border: none;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
