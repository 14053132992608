import React, { useEffect, useMemo } from "react";
import Slider from "react-slick";

import { StyledCarouselButton } from "./style";

export const Carousel = ({ slidesToShow, children, nextArrow, prevArrow, sliderRef }) => {
    const settings = useMemo(() => {
        return {
            infinite: true,
            speed: 500,
            slidesToShow: slidesToShow || 3,
            slidesToScroll: 1,
            nextArrow: nextArrow || <StyledCarouselButton className="carousel-next" isBigButton={slidesToShow === 2} />,
            prevArrow: prevArrow || <StyledCarouselButton className="carousel-prev" isBigButton={slidesToShow === 2} />
        }
    }, [nextArrow, prevArrow, slidesToShow]);

    useEffect(() => {
        settings.slidesToShow = slidesToShow;
    }, [slidesToShow, settings]);

    return (
        <Slider ref={sliderRef} {...settings}>
            {children}
        </Slider>
    );
};
