import * as React from "react";

import { Star } from "../Star/Star";

import { StyledWelcome, StyledWelcomeTextWrapper } from "./style";
import { StyledTitle, StyledText } from "../IndexPageComponent/style";

export const Welcome = () => (
    <StyledWelcome>
        <StyledTitle>
            <Star />
            <h2>WELCOME TO CINDERELLA</h2>
            <Star />
        </StyledTitle>
        <StyledWelcomeTextWrapper>
            <StyledText>
                I can hear someone crying quietly in Cinderella’s Garden. The ball is just about to start, but Cinderella’s ballgown is torn and stained! Use lunar magic to
                complete all the mini-games, and help Cinderella make it to the ball!
            </StyledText>
            <StyledText>
                I apologize for not introducing myself right away. My name is Fairy Godmother. I’m currently stuck in the Wonder Academy and unable to help Cinderella. Can I count
                on you for help?
            </StyledText>
        </StyledWelcomeTextWrapper>
    </StyledWelcome>
);
