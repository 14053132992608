import React from "react";
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

import { StyledOverlay, StyledOverlayBg, StyledOverlayCarouselWrapper } from "./style";

const OverLay = ({ children, onOutsideClick, breakpoints }) => {
    const isTabletOrMobile = breakpoints.l;
    const isPortrait = breakpoints.portrait;

    return (
        <StyledOverlay>
            <StyledOverlayBg onMouseDown={() => onOutsideClick()} />
            <StyledOverlayCarouselWrapper
                isTabletOrMobile={isTabletOrMobile}
                isPortrait={isPortrait}
            >
                {children}
            </StyledOverlayCarouselWrapper>
        </StyledOverlay>
    );
};

export default withBreakpoints(OverLay);
