import styled from "styled-components";

export const StyledMarkets = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
`;

export const StyledMarketsList = styled.ul`
    display: flex;
    flex: 0 1 865px;
    justify-content: ${({isShowAmazon}) => isShowAmazon ? "space-between" : "space-around"};
    align-items: center;
    z-index: 1;

    .itemImg {
        width: 260px;
        cursor: pointer;
    }
`;
