import styled from "styled-components";
import { black } from "../../styles/variables";

export const StyledOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

export const StyledOverlayBg = styled.div`
    background-color: ${black};
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
`;

export const StyledOverlayCarouselWrapper = styled.div`

    ${({isTabletOrMobile, isPortrait}) => {
        if (!isTabletOrMobile) {
            return `
            width: 100%;
            max-width: 450px;`
        }

        if (isTabletOrMobile && !isPortrait) {
            return `    
            max-width: calc(100%/4);
            width: 100%;`
        }

        return `    
            height: auto;
            width: 70vw;
            max-width: none;`
    }}

    position: relative;
    z-index: 10;
`;
