import styled from "styled-components";

export const StyledWelcome = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 140px;
    padding-top: 70px;
    margin: 0 0 40px 0;
    z-index: 3;
`;

export const StyledWelcomeTextWrapper = styled.div`
    padding: 0 80px;
    width: 100%;
`;
