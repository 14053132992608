import * as React from "react";

import { StyledVideoWrappe, StyledCodegena, StyledIFrame } from "./style";

export const Video = () => (
    <StyledVideoWrappe>
        <StyledCodegena>
            <StyledIFrame loading="lazy" src="https://www.youtube.com/embed/SaE-poepETc" frameborder="0" />
        </StyledCodegena>
        <div style={{ fontSize: "0.8em" }}></div>
    </StyledVideoWrappe>
);
