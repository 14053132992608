import * as React from "react";

import IndexPageComponent from "../components/IndexPageComponent/IndexPageComponent";
import { Layout } from "../components/Layout/Layout";
import Seo from "../components/seo";

const IndexPage = () => (
    <Layout>
        <Seo title="Home" />
        <IndexPageComponent />
    </Layout>
);

export default IndexPage;
