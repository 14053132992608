import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { StyledMarkets, StyledMarketsList } from "./style";

export const Markets = () => {
    const isShowAmazon = false;

    return (
        <StyledMarkets>
            <StyledMarketsList isShowAmazon={isShowAmazon}>
                <li>
                    <Link to="https://apps.apple.com/by/app/cinderella-new-story/id1541619373">
                        <StaticImage className="itemImg" src="../../images/store/app_store.png" alt="App Store" placeholder="blurred" />
                    </Link>
                </li>
                <li>
                    <Link to="https://play.google.com/store/apps/details?id=com.may_play.cinderella">
                        <StaticImage className="itemImg" src="../../images/store/google_play.png" alt="Google Play" placeholder="blurred" />
                    </Link>
                </li>
                {isShowAmazon && (
                    <li>
                        <Link to="/">
                            <StaticImage className="itemImg" src="../../images/store/amazon.png" alt="Amazon App Store" placeholder="blurred" />
                        </Link>
                    </li>
                )}
            </StyledMarketsList>
        </StyledMarkets>
    )
};
